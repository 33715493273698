
import React, { Component } from "react";
import "./styles.css";


export class Header extends Component {
	render() {
		return (
			<nav>
				<div className="left">
					<div className="logo"></div>
					<div className="title">expansion<br/>beyond</div>
				</div>
			</nav>
		);
	}
}

export class Footer extends Component {
	render() {
		if(this.props.signup) {
			return (
				<footer>
					<div id="footer-content">
						<p>Want to know when it's ready?</p>
						<form>
							<input type="email" name="EMAIL" id="mce-EMAIL" placeholder="Your email address" />
							<button type="submit" className="button notify-me">Notify Me</button>
						</form>
					</div>
					<p className="small">&copy; Copyright Expansion Games. All rights reserved. Read the <a href="website-terms">website terms</a> or <a href="mailto:hello@expansion.games">contact us</a>.</p>
				</footer>
			);
		}
		else {
			return (
				<footer>
					<p className="small">&copy; Copyright Expansion Games. All rights reserved. Read the <a href="website-terms">website terms</a> or <a href="mailto:hello@expansion.games">contact us</a>.</p>
				</footer>
			);
		}
	}
}

export class EmailButton extends Component {
	render() {
		const subject = this.props.subject ? this.props.subject : this.props.label;
		return (<a href={"mailto:hello@expansion.games?subject="+subject}><button type="submit" className="button enabled">{this.props.label}</button></a>);
	}
}

export class BackLink extends Component {
	render() {
		return (<p className="backLink"><a className="highlight" href="/">Return to the home page</a></p>);
	}
}

export class EmailLink extends Component {
	render() {
		if(this.props.label) {
			return (<a href={"mailto:hello@expansion.games?subject="+this.props.label}>{this.props.label}</a>);
		}
		else {
			return (<a href="mailto:hello@expansion.games">hello@expansion.games</a>);
		}
	}
}

export class Separator extends Component {
	render() {
		return (<p className="separator"/>);
	}
}