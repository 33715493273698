import React, { Component } from "react";
import "./styles.css";
import * as c from "./components";


// Pages:
export class Home extends Component {
	render() {
		return (
			<div className="grid">
				<c.Header/>
				<main>
					<div id="summary">
						<p>A mobile game about space exploration and first contact with alien life.</p>
						<p>Realistic, detailed, immersive.</p>
					</div>
					<ul className="mainLinks">
						<li><a href="https://youtu.be/gj1kTjHgpqA" target="blank">Play intro</a></li>
						<li><a href="https://sev.global" target="_blank">About your mission</a></li>
						<li><a href="https://twitter.com/ExpansionBeyond" target="_blank">Follow us on Twitter</a></li>
						<li><a href="https://www.facebook.com/ExpansionBeyond" target="_blank">Like us on Facebook</a></li>
						<li><c.EmailLink label="Email us"/></li>
					</ul>
				</main>
				<c.Footer signup={true}/>
			</div>
		);
	}
}

export class Help extends Component {
	render() {
		return (
			<div className="grid">
				<c.Header/>
				<main>
					<h1>Help & Support</h1>
					<p>Having problems playing Expansion Beyond?</p>
					<p>We're here to help. Drop us a line explaining the problem and we'll get back to you soon.</p>
					<c.Separator/>
					<c.EmailButton label={"Get Help"}/>
					<c.BackLink />
				</main>
				<c.Footer />
			</div>
		);
	}
}

export class ChangeEmailAddress extends Component {
	render() {
		return (
			<div className="grid">
				<c.Header/>
				<main>
					<h1>Change Email Address</h1>
					<p>If you use your email address to sign in to Expansion Beyond and you need to change it, please use the button below to get in touch with us.</p>
					<c.Separator/>
					<c.EmailButton label={"Get Help"} subject={"Change Email Address"}/>
					<c.BackLink />
				</main>
				<c.Footer />
			</div>
		);
	}
}

export class Terms extends Component {
	render() {
		return (
			<div className="grid">
				<c.Header/>
				<main>
					<h1>Mobile App Terms of Service</h1>
						<p>We are Expansion Games Limited, a company registered in England and Wales (Company Number 11998484) with registered offices located at
							Suite 5, First Floor, The Counting House, Bonds Mill Estate Stonehouse, Gloucestershire, United Kingdom, GL10 3RF ("<strong>Company</strong>", "<strong>we</strong>" or "<strong>us</strong>")
							the creators of the Expansion Beyond gaming app (referred to below as the "<strong>App</strong>").</p>
						<p>By downloading the App, you are agreeing to the terms of this agreement which are legally binding. Please read it together with our <a href="privacy-policy">Privacy Policy</a> before
							you download and use the App. Only download the App if you have read the rules and agree to them.</p>
						<p>If you do not agree to these terms, we will not allow you to use the App and you should not download it. </p>
						<p>In this agreement:</p>
						<ul>
							<li>references to the "App Store" means Apple's App Store and we refer to their rules and policies contained in the Apple Media Services Terms and Conditions as the "App Store Rules".</li>
							<li>references to "Google Play" means the app distribution platform operated by Google and known as Google Play, and we refer to Google's terms of service (and other applicable terms) collectively as "Google's Rules".</li>
							<li>in clauses where we use the phrase “App Store Rules or Google's Rules (as applicable)”, we intend for the App Store Rules to apply to users who have obtained the App via the Apple App Store, and for Google's Rules to apply to users who have obtained the App via the Google Play distribution platform.</li>
						</ul>
					<h2>1. Our Services</h2>
						<ol className="alpha">
							<li>Company is the developer and supplier or the App</li>
							<li>Company has developed the App as a science fiction themed game playable on mobile devices.</li>
						</ol>
					<h2>2. Who may use the App</h2>
						<p>In order to use the App, you must be at least 18 years old or have the express consent of your legal guardian.
							You must also have the legal capacity (including being of sufficient age) to enter into contracts under the law of the jurisdiction in which you reside.
							You may not access the App if these facts are not true.</p>
					<h2>3. These Terms</h2>
						<ol className="alpha">
							<li>
								<p>These terms are an agreement is made between you and us, and:</p>
								<ol className="roman">
									<li>Apple is not a party to this agreement and has no responsibility for the app or its content; and</li>
									<li>Google is not a party to this agreement and has no liability under it.</li>
								</ol>
							</li>
							<li>
								<p>We license you to download and use the App:</p>
								<ol className="roman">
									<li>For iOS users, onto any Apple-branded product, and to use it once you have downloaded it, provided you follow all of the rules described in this agreement and the App Store Rules</li>
									<li>For Google Play Store users, to use the App provided you follow all of the rules described in this agreement and also Google's rules.</li>
								</ol>
							</li>
							<li>
								<p>The licence:</p>
								<ol className="roman">
									<li>is only for you personally and anyone else that the Apple or Google (as applicable) and for non-business use;</li>
									<li>starts when you download the App; and</li>
									<li>covers content, materials, or services accessible from, or bought in, the App including all of our support resources. It also covers updates to the App unless they come with separate terms, in which case we will give you an opportunity to review and accept.</li>
								</ol>
							</li>
							<li>You must comply with the App Store Rules and Google's Rules (as applicable) as well as these terms but, if there is any conflict between them, you should follow the App Store Rules or Google's Rules rather than the equivalent rule here.</li>
							<li>You do not own the App or any of its contents but you may use it on devices that you own or control, as permitted by these terms and by the App Store Rules or Google's Rules (as applicable).</li>
							<li>If you sell or give away the device on which you have downloaded the App, you must first remove the App from the device.</li>
							<li>
								<p>You are not allowed to:</p>
								<ol className="roman">
									<li>modify the App's code in any way, including inserting new code, either directly or through the use of another app or piece of software;</li>
									<li>deliberately attempt to avoid or manipulate any security features included in the App; or</li>
									<li>pretend that the App is your own or make it available for others to download or use (including by way of copying the code of the App and creating an independent version).</li>
								</ol>
							</li>
						</ol>
					<h2>4. Signing up and In-App Purchases</h2>
						<ol className="alpha">
							<li>In order to play the game, you are required to sign up.
								When you sign up, you will be required to provide your email address and we will assign a unique username and send you an email on the said email address with a unique code for verification and use of the App.
								To resume a previously played game, you will need to sign in using the email address provided earlier while playing the game.</li>
							<li>The App is presently free to download and use, i.e., you are not required to pay any fee to download the App or take any subscription to play the game made available through the App.</li>
							<li>The App may contain In-App Purchases, i.e., there may be certain items and consumables such as neutron cells, power-ups, permanent upgrades, cosmetic items etc.,
								which you to speed up or customize your experience of playing the game, which you may need to buy while playing the game made available through the App.
								Payments for any such In-App Purchases will be made through App Store or Google Play, as the case may be, and will thus be subject to the App Store Rules or Google's Rules (as applicable).</li>
							<li>Any In-App Purchases are specific to the user account through which an In-App Purchase is made and no In-App Purchases may be transferred from one account to another account even if it's a family sharing account.</li>
							<li>Any items and consumables which are completely unused, may be returned by you within a period of 14 days from the date of purchase and any refunds for such returned items and consumables will be processed using the same method payment was made by you, i.e.,
								through App Store or Google Play, as the case may be. In case any items have been partly used (for instance any neutron cells from a pack of 100 neutron cells), then refund will not be provided for such partly unused items or consumables.</li>
						</ol>
					<h2>5. Support and contact</h2>
						<ol className="alpha">
							<li>We are responsible for customer service in relation to the App and can help you if you are having any issues.</li>
							<li>For iOS users, you acknowledge that Apple has no obligation whatsoever to provide any support or maintenance services in relation to the App.</li>
							<li>For Google users, you acknowledge that Google has no obligation to provide any support or maintenance services in relation to the App.</li>
							<li>If you need to get in touch with us, you can use the 'contact us' functionality provided in the app.</li>
							<li>If we need to get in touch with you, we will do so by email or an in-app notification.</li>
						</ol>
					<h2>6. Privacy and your personal information</h2>
						<p>Protecting your personal information is important to us. Our <a href="privacy-policy">Privacy Policy</a> explains what personal information we collect from you, how and why we collect, store, use and share such information,
							your rights in relation to it and how to contact us and supervisory authorities if you have a query or complaint.</p>
					<h2>7. Collection of technical information</h2>
						<p>We may collect and use technical data that might include, for example, the specifications of your device and its software in order to help us provide software updates, product support, and other services related to the App.
							We may also use this information, as long as it is in a form that does not personally identify you, to improve products or to offer new services or technologies to you.</p>
					<h2>8. Ownership, Use and Intellectual Property Rights</h2>
						<ol className="alpha">
							<li>The intellectual property rights in the App and in any text, images, video, audio or other multimedia content, software or other information or material submitted to or accessible from the App (the "<strong>Content</strong>") are owned by us and our licensors.</li>
							<li>We and our licensors reserve all our intellectual property rights (including, but not limited to, all copyright, patents, trademarks, service marks, domain names, design rights, database rights, patents and all other intellectual property rights of any kind)
								whether registered or unregistered anywhere in the world. This means, for example, that we remain owners or licensors of them and are free to use them as we see fit.</li>
							<li>Nothing in these terms grant you any legal rights in the App or the Content other than as necessary for you to access it and use it.
								You agree not to adjust, try to circumvent or delete any notices contained in the App or the Content (including any intellectual property notices) and in particular, in any digital rights or other security technology embedded or contained within the Site or the Content.</li>
							<li>Trademarks: “Expansion Beyond”, and the associated logos within our app are the registered trademarks of the Company.
								Other trademarks and trade names may also be used on the App or in the Content. Use by you of any trademarks on the App or in the Content is strictly prohibited unless you have our prior written permission.</li>
						</ol>
					<h2>9. Acceptable use</h2>
						<ol className="alpha">
							<li>
								<p>You must not use the App to do any of the following things:</p>
								<ol className="roman">
									<li>break the law or encourage any unlawful activity;</li>
									<li>send or upload anything that is (or might be considered to be) defamatory, offensive, obscene, containing or representing sexually explicit images and/or discriminatory (including but not limited to, in relation to race, gender, religious beliefs, sexual orientation or disability);</li>
									<li>infringe our or anyone else's intellectual property rights (for example, by using or uploading someone else's content);</li>
									<li>transmit any harmful software code such as viruses;</li>
									<li>try to gain unauthorised access to computers, data, systems, accounts or networks; or</li>
									<li>deliberately disrupt the operation of anyone's website, mobile application, server or business.</li>
								</ol>
							</li>
							<li>Company reserves the right to delete any Content which is not compliant with present clause 9.</li>
							<li>
								<p>Any submission you make to the App, including but not limited to the Forum (as defined in clause 1(b)), and any other communication to users of the App by you must conform to standards of accuracy, decency and lawfulness, which shall be applied in our discretion, acting reasonably.
									In particular, any submission or communication by you must be:</p>
								<ol className="roman">
									<li>not defamatory or likely to give rise to an allegation of defamation;</li>
									<li>not offensive, obscene, sexually explicit, discriminatory or deceptive;</li>
									<li>unlikely to cause offence, embarrassment or be construed as, or constitute harassment to others;</li>
									<li>factually accurate or your own genuinely held belief; and</li>
									<li>your own original work (where applicable) and lawfully submitted.</li>
								</ol>
							</li>
							<li>While we use all commercially reasonable efforts to make sure that the App is secure and accurate, we do not actively monitor or check at all times whether information supplied to us through the App is factual, exact, confidential, commercially sensitive or valuable.</li>
							<li>Other than any personal information which will be dealt with in accordance with our Privacy Policy, we do not guarantee that information supplied to us through the App will be kept confidential and we may use it on an unrestricted and free-of-charge basis as we reasonably see fit.</li>
						</ol>
					<h2>10. Data protection</h2>
						<h3>10.1. Data protection</h3>
							<ol className="alpha">
								<li>Words and phrases in this section shall have the meaning given to them by applicable data protection and privacy laws,
									including the General Data Protection Regulation 2016/679 (GDPR) and applicable national legislation that implements or supplements the GDPR or otherwise applies to data protection and privacy,
									and any statutory instrument, order, rule or regulation made thereunder, as from time to time amended, extended, re-enacted or consolidated (<strong>Data Protection Legislation</strong>)
									and the terms “controller”, “processor”, “process” and “personal data” shall have the meanings given to those terms in such Data Protection Legislation.</li>
								<li>
									<p>During and after use of the App and associated services, you agree that Company will be processing personal data for its own purposes and as such will be a controller under the Data Protection Legislation and this includes (but is not limited to) the following purposes:</p>
									<ol className="roman">
										<li>Company providing the App in accordance with this agreement;</li>
										<li>Company and/or our independent contractors and third party suppliers may use your contact details to send marketing materials or other publications;</li>
										<li>Company may process personal data concerning its other customers and contacts in other ways for its own business purposes;</li>
										<li>Company may process and transfer personal data as necessary to effect a re-organisation of its business; and</li>
										<li>Company may share personal data with other legal or professional advisers used by us to provide you with professional services.</li>
									</ol>
								</li>
								<li>During and after use of the App and associated services, there may be limited occasions where Company may process on your behalf as a processor any personal data you have provided to Company.
									Company will advise you in writing where Company believes Company may act as a processor and any such processing shall be in accordance with applicable laws.</li>
								<li>Before performing the processing, Company shall document within the instructions the subject matter and duration of the processing, the nature and purpose of the processing,
									the types of personal data and categories of data subjects and the other terms prescribed by the Data Protection Legislation.
									Company will ensure that all appropriate technical and organisational measures are taken to protect any personal data supplied by you to Company against unauthorised or unlawful processing,
									accidental loss, destruction or damage, including when Company subcontracts any processing (for example, in the case of external storage of data).</li>
								<li>Your instructions are taken to include the use by Company, where appropriate, of independent contractors and third party suppliers appointed by us for functions such as data and file storage, back-up, destruction, billing, debt collection, legal processing and the like, in accordance with the foregoing.</li>
								<li>By accepting this agreement you give positive consent for Company to obtain, store and process information about you as described in the preceding paragraphs.
									You agree that where necessary you will have satisfied relevant statutory ground under the Data Protection Legislation in connection with the above-described categories of processing, before providing Company with personal data.
									It is also a term of this agreement that any personal data supplied by Company to you about employees/independent contractors of Company and/or any third parties may only be used for the express purposes for which that information is provided to you.</li>
								<li>Each party shall comply with the terms of the Data Protection Legislation.</li>
							</ol>
						<h3>10.2. Third party data</h3>
							<ol className="alpha">
								<li>
									<p>You warrant, in relation to the personal information and all other data you provide to Company in connection with this agreement ("<strong>Third Party Data</strong>"), that:</p>
									<ol className="roman">
										<li>you have all necessary rights in relation to Third Party Data;</li>
										<li>you are not breaching any Law by providing Company with Third Party Data;</li>
										<li>Company will not breach any Law by providing the App in relation to any Third Party Data;</li>
										<li>there are no restrictions placed on the use of the Third Party Data (including by any Third Party Terms) and if there are any such restrictions, you have notified Company of this, and Company has agreed to provide the App in respect of that data (being under no obligation to do so); and</li>
										<li>Company will not breach any Third Party Terms by providing the App in relation to any Third Party Data.</li>
									</ol>
								</li>
								<li>You agree at all times to indemnify and hold harmless Company and its officers, employees and agents from and against any loss (including reasonable legal costs) or liability incurred or suffered by any of those parties, where such loss or liability was caused or contributed to a breach of a warranty in clause 10.2(a).</li>
							</ol>
					<h2>11. Security</h2>
						<p>We do not accept responsibility for any unauthorised use, destruction, loss, damage or alteration to your data or information, your computer systems, mobile phones or other electronic devices arising in connection with use of the App.
							You should take your own precautions to ensure that the process which you employ for accessing the App does not expose you to the risk of hacking, malware, ransomware, viruses, malicious computer code or other forms of interference.</p>
					<h2>12. Updates to the App</h2>
						<ol className="alpha">
							<li>We may update the App from time to time for reasons that include fixing bugs or enhancing functionality. We might also change or remove functionality but if we do that, we will ensure that the App still meets the description of it that was provided to you at the time you downloaded the App.</li>
							<li>Updates will either download automatically or you may need to trigger them yourself, depending on your device, its settings and the app store.</li>
							<li>We strongly suggest that you download all updates as soon as they become available. Depending on the nature of the update, the App may not work properly (or at all), or you may be exposed to security vulnerabilities, if you do not keep the App updated to the latest version that we make available.</li>
						</ol>
					<h2>13. Changes to these terms</h2>
						<ol className="alpha">
							<li>We may need to revise these terms from time to time to reflect changes in the App’s functionality, to deal with a security threat or if there is a change in the law or guidance.</li>
							<li>You will be asked to agree to any material changes in advance by an in-app notification , usually when you download an update. If you do not accept the changes, you may not be able to use the App.</li>
						</ol>
					<h2>14. External services and social media</h2>
						<ol className="alpha">
							<li>The App may enable you to access services and websites including social media websites such as Twitter/X, Facebook etc, that we do not own or operate (referred to below as "external services").</li>
							<li>We are not responsible for examining or evaluating the content or accuracy of these external services.
								Before using them, make sure you have read and agreed to the terms on which they are being offered to you including the way in which they may use your personal information.</li>
							<li>
								<p>You must not use external services in any way that:</p>
								<ol className="roman">
									<li>is inconsistent with these terms or with the terms of the external service; or</li>
									<li>infringes our intellectual property rights, or the intellectual property rights of any third party.</li>
								</ol>
							</li>
							<li>From time to time, we may change or remove the external services that are made available through the App.</li>
							<li>The Company operates a Discord server to maintain a community dedicated to discussing the game. Interactions within the said Discord community may happen amongst different individuals who may be Company's employees or game players.
								Your account on the App is not connected with or linked with your Discord profile.
								Any use of the Discord app or website for joining the community as well as any interaction, exchange of information taking place over Discord community will be subject to the terms and conditions of use and privacy policy of Discord, on which the Company does not exercise any control.
								The Company does not provide any support related to the App through the Discord community.</li>
							<li>The Company may also set-up and operate different social media channels such as Facebook, Twitter/X etc., which will be subject to the terms and conditions and privacy policy of the respective social media platform, on which the Company does not exercise any control.
								The Company will also not provide any support related to the App through its social media channels.</li>
						</ol>
					<h2>15. Liability</h2>
						<ol className="alpha">
							<li>Nothing in these terms shall exclude or limit the Company's liability for death or personal injury caused by our negligence or willful misconduct, liability for fraud or fraudulent misrepresentation, or any other liability which cannot be excluded or limited under the laws of England and Wales.</li>
							<li>Subject to clause 15(a), Company shall bear no liability of any kind whatsoever for (but not limited to) any direct or indirect/consequential, foreseeable or unforeseeable,
								losses, costs, damages, fees, expenses, any inaccuracy or misleading information, any loss of profit, revenue, suffered by you or any third-party, relating to, in connection with, or arising from, this agreement, the Company's products and services and/or the App.</li>
							<li>In any case, subject to clause 15(a) and to the extent permitted under applicable law, the Company's total aggregate liability arising from or in connection with this agreement and/or the App (whether the liability arises because of breach of contract, tort or for any other reason)
								shall be limited to no more than fifty pounds sterling (£50).</li>
						</ol>
					<h2>16. Failures of networks or hardware</h2>
						<p>The App relies on a number of things working properly to enable you to enjoy all of its features.
							Many of these, such as your internet connection, your device and the App Store or Google Play (as applicable), are entirely outside of our control.
							Although we will do everything we reasonably can to resolve issues, we are not responsible to you if you are unable to use all or any part of the App due to a poor internet connection, faulty components in your device
							(such as a faulty GPS signal, failing in the API with any suppliers of our Content or related features), App Store failure or failure of Google Play to function properly (as applicable) or anything else that it would not be reasonable to expect us to control.</p>
					<h2>17. Ending these terms</h2>
						<ol className="alpha">
							<li>We can end these terms and our agreement with you, if you do not comply with any part of them</li>
							<li>We will give you a reasonable amount of notice before the terms and our agreement with you ends but if what you have done is serious then we may end our agreement with you immediately and without advance notice to you.
								"Serious" means that you are causing harm (or attempting to cause harm) to other users, interfering with the operation of the App or doing anything else that we think presents a big enough risk to justify us ending our agreement with you quickly.</li>
							<li>
								<p>The consequences of our agreement with you ending are as follows:</p>
								<ol className="roman">
									<li>you are no longer allowed to use the App and we may remotely limit your access to it;</li>
									<li>you must delete it from any devices that it has been installed on;</li>
									<li>we may delete or suspend access to any accounts that you hold with us; and</li>
									<li>you are not entitled to a refund to the extent you paid for the App of any of its features.</li>
								</ol>
							</li>
						</ol>
					<h2>18. Third parties</h2>
						<ol className="alpha">
							<li>
								<p>No one other than us or you has any right to enforce of these terms, except that:</p>
								<ol className="roman">
									<li>in the case of iOS users, Apple and Apple's subsidiary companies are third party beneficiaries of these terms and to this agreement.
										This means that if you breach any of these terms, Apple has the right to enforce it and to take action against you directly, with or without our involvement; and</li>
									<li>in the case of Android users, Google and Google's subsidiary companies are third party beneficiaries of these terms and to this agreement.
										This means that if you breach any of these terms, Google has the right to enforce it and to take action against you directly, with or without our involvement.</li>
								</ol>
							</li>
						</ol>
					<h2>19. Transferring these terms</h2>
						<ol className="alpha">
							<li>We may transfer our rights under these terms to another business without your consent, but we will notify you of the transfer and make sure that you are not adversely affected as a result.</li>
							<li>You are not allowed to transfer your rights under these terms to anyone without our prior written consent.</li>
						</ol>
					<h2>20. Governing law and jurisdiction</h2>
						<ol className="alpha">
							<li>The laws of England and Wales apply to this agreement, although if you are resident elsewhere acting in a non-professional capacity, you will retain the benefit of any mandatory protections given to you by the laws of the country in which you live.</li>
							<li>Any disputes will be subject to the non-exclusive jurisdiction of the English courts. This means that you can choose whether to bring a claim in the courts of England or in the courts of another part of the UK in which you live.</li>
						</ol>
					<c.BackLink/>
				</main>
				<c.Footer signup={false}/>
			</div>
		);
	}
}

export class WebsiteTerms extends Component {
	render() {
		return (
			<div className="grid">
				<c.Header/>
				<main>
					<h1>Website Terms of Service</h1>
					<h2>1. Introduction</h2>
						<ol className="alpha">
							<li>These terms and conditions (<strong>Terms</strong>) apply when you use our websites, www.expansionbeyond.com and www.sev.global (<strong>Website</strong>).</li>
							<li>You agree to be bound by these Terms which form a binding contractual agreement between you and us, Expansion Games Limited, a company registered in England and Wales under company number 11998484 with registered office at
								Suite 5, First Floor, The Counting House, Bonds Mill Estate Stonehouse, Gloucestershire, United Kingdom, GL10 3RF ("<strong>we</strong>", "<strong>our</strong>" or "<strong>us</strong>").</li>
							<li>If you don't agree to these Terms, you must refrain from using the Website.</li>
							<li>We may change these Terms at any time by updating this page of the Website, and your continued use of the Website following such an update will represent an agreement by you to be bound by the Terms as amended.</li>
						</ol>
					<h2>2. Access and use of the Website</h2>
						<p>You must only use the Website in accordance with these Terms and any applicable laws, and must ensure that your employees, sub-contractors and any other agents who use or access the Website comply with the Terms and any applicable laws.</p>
					<h2>3. Your Obligations</h2>
						<p>You must not:</p>
						<ol className="alpha">
							<li>copy, mirror, reproduce, translate, adapt, vary, modify, sell, decipher or decompile any part or aspect of the Website without our express consent;</li>
							<li>use the Website for any purpose other than the purposes of browsing or accessing information about the services provided by us;</li>
							<li>use, or attempt to use, the Website in a manner that is illegal or fraudulent or facilitates illegal or fraudulent activity;</li>
							<li>use, or attempt to use, the Website in a manner that may interfere with, disrupt or create undue burden on the Website or the servers or networks that host the Website;</li>
							<li>use the Website with the assistance of any automated scripting tool or software;</li>
							<li>act in a way that may diminish or adversely impact our reputation, including by linking to the Website on any other website; and</li>
							<li>
								<p>attempt to breach the security of the Website, or otherwise interfere with the normal functions of the Website, including by:</p>
								<ol className="roman">
									<li>gaining unauthorised access to Website accounts or data;</li>
									<li>scanning, probing or testing the Website for security vulnerabilities;</li>
									<li>overloading, flooding, mailbombing, crashing or submitting a virus to the Website; or</li>
									<li>instigate or participate in a denial-of-service attack against the Website.</li>
								</ol>
							</li>
						</ol>
					<h2>4. Information on the Website</h2>
						<p>While we will use our best endeavours to ensure the Website is as up-to-date and accurate as possible, you acknowledge and agree that from time to time, you may encounter the following issues:</p>
						<ol className="alpha">
							<li>the Website may have errors or defects;</li>
							<li>the Website may not be accessible at times;</li>
							<li>messages sent through the Website may not be delivered promptly, or delivered at all;</li>
							<li>information you receive or supply through the Website may not be secure or confidential; or</li>
							<li>any information provided through the Website may not be accurate or true.</li>
						</ol>
						<p>We reserve the right to change any information, content or functionality on the Website by updating the Website at any time without notice,
							including nature and description of services provided, prices and other Website Content.</p>
					<h2>5. Intellectual Property</h2>
						<ol className="alpha">
							<li>We retain ownership of the Website and all materials on the Website (including text, graphics, logos, design, icons, images, sound and video recordings, pricing, downloads and software)
								(<strong>Website Content</strong>) and reserve all rights in any intellectual property rights owned or licensed by it not expressly granted to you.</li>
							<li>You may make a temporary electronic copy of all or part of the Website for the sole purpose of viewing it.
								You must not otherwise reproduce, transmit, adapt, distribute, sell, modify or publish the Website or any Website Content without prior written consent from us or as permitted by law.</li>
						</ol>
					<h2>6. Links to other websites</h2>
						<ol className="alpha">
							<li>The Website may contain links to other websites that are not our responsibility. We have no control over the content of the linked websites and we are not responsible for it.</li>
							<li>Inclusion of any linked website on the Website does not imply our approval or endorsement of the linked website.</li>
						</ol>
					<h2>7. Security</h2>
						<p>We do not accept responsibility for loss or damage to computer systems, mobile phones or other electronic devices arising in connection with use of the Website.
							You should take your own precautions to ensure that the process that you employ for accessing the Website does not expose you to risk of viruses, malicious computer code or other forms of interference.</p>
					<h2>8. Reporting Misuse</h2>
						<p>If you become aware of misuse of the Website by any person, any errors in the material on the Website or any difficulty in accessing or using the Website,
							please contact us immediately using the contact details (see below "<strong>How to contact us</strong>").</p>
					<h2>9. Privacy</h2>
						<p>You agree to be bound by our Privacy Policy, which can be found <a href="privacy-policy">here</a>.</p>
					<h2>10. Liability</h2>
						<p>We make no warranties or representations about this Website or any of its content and will not be responsible to you or any third party for any direct or consequential loss suffered in connection with the use of this Website.</p>
					<h2>11. General</h2>
						<h3>11.1. Governing law and jurisdiction</h3>
							<p>These Terms are governed by the law applying in England and Wales.
								Each party irrevocably submits to the exclusive jurisdiction of the courts of England and Wales and courts of appeal from them in respect of any proceedings arising out of or in connection with these Terms.</p>
						<h3>11.2. Waiver</h3>
							<p>No party to these Terms may rely on the words or conduct of any other party as a waiver of any right unless the waiver is in writing and signed by the party granting the waiver.</p>
						<h3>11.3. Severance</h3>
							<p>Any term of these Terms which is wholly or partially void or unenforceable is severed to the extent that it is void or unenforceable.
								The validity and enforceability of the remainder of these Terms is not limited or otherwise affected.</p>
					<h2>12. How to contact us</h2>
						<p>You can contact us by email if you have any questions about these terms. You may send your email to <c.EmailLink/>.</p>
					<c.BackLink/>
				</main>
				<c.Footer signup={false}/>
			</div>
		);
	}
}

export class Privacy extends Component {
	render() {
		return (
			<div className="grid">
				<c.Header/>
				<main>
					<h1>Privacy Policy</h1>
						<p>Last Updated: 26 May 2024</p>
					<h2>1. Introduction</h2>
						<p>This Privacy Policy is provided by Expansion Games Limited, a company registered in England and Wales under company number 11998484 with registered office at
							Suite 5, First Floor, The Counting House, Bonds Mill Estate Stonehouse, Gloucestershire, United Kingdom, GL10 3RF
							("<strong>we</strong>", "<strong>our</strong>" or "<strong>us</strong>") for use of our websites www.expansionbeyond.com and www.sev.global and Expansion Beyond gaming app (collectively referred to as the "<strong>Website</strong>").</p>
						<p>We take your privacy very seriously. Please read this privacy policy carefully as it contains important information on how and why we collect, store, use and share any information relating to you (your <strong>personal data</strong>).</p>
						<p>It also explains your rights in relation to your personal data and how to contact us or the relevant regulator in the event you have a complaint.
							Our collection, storage, use and sharing of your personal data is regulated by law, including under the UK General Data Protection Regulation (<strong>UK GDPR</strong>).</p>
						<p>We are the controller of personal data obtained via the Website, meaning we are the organisation legally responsible for deciding how and for what purposes it is used.</p>
					<h2>2. What this policy applies to</h2>
						<p>This privacy policy relates to your use of the Website and accessing the services provided through the Website.</p>
						<p>The Website may link to or rely on other apps, websites, APIs or services owned and operated by us or by certain trusted third parties to enable us to provide you with the services through the Website.
							These other apps, websites, APIs or services may also gather information about you in accordance with their own separate privacy policies.
							For privacy information relating to these other apps, websites or services, please consult their privacy policies as appropriate.
							For more information see the section "<strong>Who we share your personal data with</strong>" below.</p>
					<h2>3. Personal data we collect about you</h2>
						<p>The personal data we collect about you depends on the particular activities carried out through the Website.
							We will collect and use the following personal data about you:</p>
							<table>
							<tr>
								<th width="20%">Category of data</th>
								<th width="80%">In more detail</th>
							</tr>
							<tr>
								<td>Identity and account data you input into the Website</td>
								<td>
									<ul>
										<li>Your email address.</li>
										<li>Your interests, preferences, feedback and survey responses.</li>
									</ul>
								</td>
							</tr>
							<tr>
								<td>Data collected when you use specific functions in the Website</td>
								<td>
									<ul>
										<li>Data you store online with us using the Website including your usage history
										(while such data may not always be personal data as defined at law in all cases we will assume it is and treat it in accordance with this policy as if it were).</li>
									</ul>
								</td>
							</tr>
							<tr>
								<td>Other data the Website collects automatically when you use it</td>
								<td>
									<ul>
										<li>Your activities on, and use of, the Website which reveal your preferences, interests or manner of use of the Website and the times of use.</li>
										<li>IP Address, Cookies, User Agent, Device Fingerprinting (which includes MAC address), Session IDs, Referral URLs, Tracking Pixels.</li>
									</ul>
								</td>
							</tr>
							<tr>
								<td>Data collected when you make an enquiry with us:</td>
								<td>
									<ul>
										<li>Your name.</li>
										<li>Your email address.</li>
									</ul>
								</td>
							</tr>
							<tr>
								<td>Marketing and communications data</td>
								<td>
									<ul>
										<li>Your preferences in receiving marketing emails from us and our third parties and your communication preferences.</li>
									</ul>
								</td>
							</tr>
						</table>
						<p>&nbsp;</p>
						<p>We also collect, use and share aggregated data such as statistical or demographic data which is not personal data as it does not directly (or indirectly) reveal your identity.
							For example, we may aggregate individuals' usage data (including information about how users interact with and use the Website and products and services offered through the Website)
							to calculate the percentage of users accessing a specific feature of the Website in order to analyse general trends in how users are interacting with the Website to help improve the Website and our offerings.</p>
						<p>We collect and use this personal data for the purposes described in the section "<strong>How and why we use your personal data</strong>" below.</p>
					<h2>4. Sensitive Data</h2>
						<p>Sensitive personal data (also known as special category data) means information related to personal data revealing racial or ethnic origin; political opinions; religious or philosophical beliefs; trade union membership;
							genetic data; biometric data (where used for identification purposes); data concerning health; data concerning a person's sex life; and data concerning a person's sexual orientation.</p>
						<p>Please note that we do not knowingly or intentionally collect sensitive personal data or information about criminal convictions from individuals and that you should not submit sensitive data to us.</p>
						<p>If, however, you do submit sensitive data to us, such as if you make this sensitive data available to other users of the Website (such as by publishing it through any forum feature we may make available from time to time)
							we will assume that you have purposefully made any such sensitive data manifestly public.</p>
					<h2>5. How your personal data is collected</h2>
						<p>We use different methods to collect data from and about you including through:</p>
						<ol>
							<li><strong>Your interaction with us</strong>: We collect personal data from you directly when you sign up to the Website, sign up to the mobile app, subscribe to our communications, request for marketing emails to be sent to you,
								contact us directly or reach out to us via social media, make submissions via the Website when a forum element is available, or indirectly, such as your activity while using the Website, participate in any feedback or survey.</li>
							<li><strong>Automated technologies or interactions</strong>: As you interact with the Website, we will automatically collect technical data about your equipment, browsing actions and patterns.
								We collect this personal data by using cookies, server logs and other similar technologies. Please see our <a href="cookie-policy">cookie policy</a> for further details.</li>
						</ol>
					<h2>6. How and why we use your personal data</h2>
						<p>Under data protection law, we can only use your personal data if we have a proper reason, e.g.:</p>
						<ol className="roman">
							<li>where you have given consent</li>
							<li>to comply with our legal and regulatory obligations</li>
							<li>for the performance of a contract with you or to take steps at your request before entering into a contract, or</li>
							<li>for our legitimate interests or those of a third party</li>
						</ol>
						<p>A legitimate interest is when we have a business or commercial reason to use your information, so long as this is not overridden by your own rights and interests.
							We will carry out an assessment when relying on legitimate interests, to balance our interests against your own. You can obtain details of this assessment by contacting us (see "<strong>How to contact us</strong>" below).</p>
						<p>The table below explains what we use your personal data for and why.</p>
						<table>
							<tr>
								<th width="40%">What we use your personal data for</th>
								<th width="60%">Our reasons</th>
							</tr>
							<tr>
								<td>Create and manage your account with us.</td>
								<td>To enable you give access to play the game on the mobile app as also to perform our contract with you or to take steps at your request before entering into a contract.</td>
							</tr>
							<tr>
								<td>Providing services and/or the functionalities of the Website to you.</td>
								<td>
									<p>Depending on the circumstances:</p>
									<ul>
										<li>to perform our contract with you or to take steps at your request before entering into a contract (in this case, the contract means the Terms and Conditions of Use which apply to the Website).</li>
										<li>to give you access to play the game on the mobile app.</li>
									</ul>
								</td>
							</tr>
							<tr>
								<td>To enforce legal rights or defend or undertake legal proceedings.</td>
								<td>
									<p>Depending on the circumstances:</p>
									<ul>
										<li>to comply with our legal and regulatory obligations.</li>
										<li>in other cases, for our legitimate interests or those of a third party, i.e. to protect our business, interests and rights or those of others.</li>
									</ul>
								</td>
							</tr>
							<tr>
								<td>Sending relevant marketing communications and for making personalised suggestions and recommendations to you about the products that may be of interest to you based on your profile data.</td>
								<td>For our legitimate interests, to carry out direct marketing, develop our products and grow our business and having obtained your consent to receive direct marketing communications.</td>
							</tr>
							<tr>
								<td>Carry out market research through your voluntary participation in surveys.</td>
								<td>Necessary for our legitimate interests to study how customers use the products, use our Website and to help us improve and develop our Website and products and services offered through the Website.</td>
							</tr>
							<tr>
								<td>Communications with you not related to marketing, including about changes to our terms or policies or updates concerning the order placed by you through the Website or changes to the Website or service or other important notices.</td>
								<td>
									<p>Depending on the circumstances:</p>
									<ul>
										<li>to comply with our legal and regulatory obligations.</li>
										<li>to keep you updated on the orders placed by you.</li>
										<li>in other cases, for our legitimate interests or those of a third party, i.e., to provide the best service to you.</li>
									</ul>
								</td>
							</tr>
							<tr>
								<td>Protect the security of systems and data.</td>
								<td>To comply with our legal and regulatory obligations we may also use your personal data to ensure the security of systems and data to a standard that goes beyond our legal obligations, 
									and in those cases our reasons are for our legitimate interests or those of a third party, i.e., to protect systems and data and to prevent and detect criminal activity that could be damaging for you and/or us.</td>
							</tr>
							<tr>
								<td>Operational reasons, such as improving efficiency, training, and quality control or to provide support to you.</td>
								<td>For our legitimate interests or those of a third party, i.e., to be as efficient as we can so we can deliver the best service to you.</td>
							</tr>
							<tr>
								<td>Statistical analysis to help us manage our business, e.g., in relation to our performance, customer base, app and functionalities and offerings or other efficiency measures.</td>
								<td>For our legitimate interests or those of a third party, i.e. to be as efficient as we can so we can deliver the best service to you and improve and develop our app.</td>
							</tr>
							<tr>
								<td>Updating and enhancing user records.</td>
								<td>
									<p>Depending on the circumstances:</p>
									<ul>
										<li>To perform our contract with you or to take steps at your request before entering into a contract (in this case, the contract means the Terms of Service which apply to the Website).</li>
										<li>To comply with our legal and regulatory obligations.</li>
									</ul>
									Where neither of the above apply, for our legitimate interests or those of a third party, e.g., making sure that we can keep in touch with our customers about their accounts and new products or functionalities related to the Website and the products and services offered through the Website.
								</td>
							</tr>
							<tr>
								<td>To comply with our legal and regulatory obligations.</td>
								<td>
									<p>Depending on the circumstances:</p>
									<ul>
										<li>to perform our contract with you or to take steps at your request before entering into a contract (in this case, the contract means the Terms of Service which apply to the Website).</li>
										<li>to comply with our legal and regulatory obligations.</li>
									</ul>
									Where neither of the above apply, for our legitimate interests or those of a third party, e.g., making sure that we can keep in touch with our customers about their accounts and new products or functionalities related to the Website and the products and services offered through the Website.
								</td>
							</tr>
							<tr>
								<td>
									<p>To share your personal data with members of our group and third parties in connection with a significant corporate transaction or restructuring, including a merger, acquisition, asset sale, initial public offering or in the event of our insolvency.</p>
									<p>In such cases information will be anonymised where possible and only shared where necessary.</p>
								</td>
								<td>
									<p>Depending on the circumstances:</p>
									<ul>
										<li>to comply with our legal and regulatory obligations.</li>
										<li>in other cases, for our legitimate interests or those of a third party, i.e., to protect, realise or grow the value in our business and assets.</li>
									</ul>
								</td>
							</tr>
						</table>
						<p>&nbsp;</p>
						<p>See "<strong>Who we share your personal data with</strong>" for further information on the steps we will take to protect your personal data where we need to share it with others.</p>
					<h2>7. Marketing</h2>
						<p>We intend to send you email marketing to inform you of any new services such as promotions, any new services, products etc.</p>
						<p>We will always ask you for your consent before sending you marketing communications, except where you have explicitly opted-in to receiving email marketing from us in the past
							or except where you were given the option to opt-out of email marketing when you initially signed up for your account with us and you did not do so.</p>
						<p>You will have the right to opt out of receiving marketing communications at any time by:</p>
						<ul>
							<li>contacting us at <c.EmailLink/>.</li>
							<li>using the "unsubscribe" link included in all marketing emails you may received from us.</li>
						</ul>
						<p>We will always treat your personal data with the utmost respect and never sell or share it with other organisations for marketing purposes.</p>
						<p>For more information on your right to object at any time to your personal data being used for marketing purposes, see "<strong>Your rights</strong>" below.</p>
					<h2>8. Who we share your personal data with</h2>
						<p>We routinely share personal data with service providers we use to help us run our business, help us with fulfilment of the orders placed through the Website
							such as payment aggregators, Apple Store or Google Play Store, or provide the services or functionalities in the Website, including developers, cloud storage providers etc.</p>
						<p>We only allow service providers to handle your personal data if we are satisfied they take appropriate measures to protect your personal data.
							We also impose contractual obligations on service providers to ensure they can only use your personal data to provide services to us and to you.</p>
						<p>We or the third parties mentioned above may occasionally also need to share your personal data with:</p>
						<ul>
							<li>external auditors, e.g. in relation to the audit of our accounts and our company —the recipient of the information will be bound by confidentiality obligations</li>
							<li>professional advisors (such as lawyers and other advisors)—the recipient of the information will be bound by confidentiality obligations</li>
							<li>law enforcement agencies, courts or tribunals and regulatory bodies to comply with legal and regulatory obligations</li>
							<li>other parties in connection with a significant corporate transaction or restructuring, including a merger, acquisition, asset sale,
								initial public offering or in the event of our insolvency — usually, information will be anonymised but this may not always be possible, however, the recipient of the information will be bound by confidentiality obligations.</li>
						</ul>
						<p>If you would like more information about who we share our data with and why, please contact us (see "<strong>How to contact us</strong>" below).</p>
						<p>We will not share your personal data with any other third party.</p>
					<h2>9. How long your personal data will be kept</h2>
						<p>We will keep your personal data for as long as you have an active account with us and for a period of up to 2 years thereafter to comply with any accounting or legal obligations including in the event of the pursuit or defence of legal claims.
							Once you have closed your account with us, we will move your personal data to a separate database so that only key stakeholders in our business on a 'need to know basis' have access to such data.</p>
						<p>Following the end of the aforementioned retention period, we will delete or anonymise your personal data.</p>
					<h2>10. Transferring your personal data out of the UK</h2>
						<p>Under UK data protection laws, we can only transfer your personal data to a country outside the UK where: the UK government has decided the particular country ensures an adequate level of protection of personal data (known as an 'adequacy regulation') further to Article 45 of the UK GDPR;
							there are appropriate safeguards in place, together with enforceable rights and effective legal remedies for you; or a specific exception applies under relevant data protection law. Accordingly, if we transfer your personal data from the UK to:</p>
						<ul>
							<li>The EEA: we would rely on the adequacy finding granted by the UK to the EU under the Withdrawal Agreement to do; for any transfers from the EU to the UK, we would rely on the adequacy regulation granted to the UK under the Adequacy Decision.</li>
							<li>Any country located outside the UK/EEA: we would rely an appropriate safeguard under the UK GDPR, such as by including the relevant Standard Contractual Clauses in our data processing agreements.</li>
						</ul>
						<p>In the event we could not or choose not to continue to rely on either of those mechanisms at any time we would not transfer your personal data outside the UK unless we could do so on the basis of an alternative mechanism or exception provided by UK data protection law.</p>
					<h2>11. Your rights</h2>
						<p>You generally have the following rights, which you can usually exercise free of charge. For more information regarding these rights, please visit the ICO website <a href="https://ico.org.uk/for-organisations/uk-gdpr-guidance-and-resources/individual-rights/individual-rights/" target="_blank">here</a>.
							The Company reserves the right to refuse to comply with any request in compliance with the Company's rights under the applicable laws.</p>
						<table>
							<tr>
								<td width="35%">Access to a copy of your personal data</td>
								<td width="75%">The right to be provided with a copy of your personal data.</td>
							</tr>
							<tr>
								<td>Correction (also known as rectification)</td>
								<td>The right to require us to correct any mistakes in your personal data.</td>
							</tr>
							<tr>
								<td>Erasure (also known as the right to be forgotten)</td>
								<td>The right to require us to delete your personal data—in certain situations.</td>
							</tr>
							<tr>
								<td>Restriction of use</td>
								<td>The right to require us to restrict use of your personal data in certain circumstances, e.g. if you contest the accuracy of the data.</td>
							</tr>
							<tr>
								<td>Data portability</td>
								<td>The right to receive the personal data you provided to us, in a structured, commonly used and machine-readable format and/or transmit that data to a third party—in certain situations.</td>
							</tr>
							<tr>
								<td>To object to use</td>
								<td>
									<p>The right to object:</p>
									<ul>
										<li>at any time to your personal data being used for direct marketing (including profiling).</li>
										<li>in certain other situations to our continued use of your personal data, e.g. where we use your personal data for our legitimate interests.</li>
									</ul>
								</td>
							</tr>
							<tr>
								<td>Not to be subject to decisions without human involvement</td>
								<td>The right not to be subject to a decision based solely on automated processing (including profiling) that produces legal effects concerning you or similarly significantly affects you.</td>
							</tr>
						</table>
						<p>&nbsp;</p>
						<p>For further information on each of those rights, including the circumstances in which they do and do not apply, please contact us (see "<strong>How to contact us</strong>" below).
							You may also find it helpful to refer to the guidance from the UK's Information Commissioner on your rights under the UK GDPR. </p>
						<p>If you would like to exercise any of those rights, please email us (see "<strong>How to contact us</strong>" below). When contacting us please:</p>
						<ul>
							<li>provide enough information to identify yourself (e.g., your full name and email address) and any additional identity information we may reasonably request from you, and</li>
							<li>let us know which right(s) you want to exercise and the information to which your request relates</li>
						</ul>
					<h2>12. Keeping your personal data secure</h2>
						<p>We have appropriate security measures to prevent personal data from being accidentally lost, or used or accessed unlawfully. We limit access to your personal data to those who have a genuine business need to access it.</p>
						<p>We also have procedures in place to deal with any suspected data security breach. We will notify you and any applicable regulator of a suspected data security breach where we are legally required to do so.</p>
						<p>If you want detailed information from Get Safe Online on how to protect your information and your computers and devices against fraud, identity theft, viruses and many other online problems, please visit <a href="https://www.getsafeonline.org" target="_blank">www.getsafeonline.org</a>.
							Get Safe Online is supported by HM Government and leading businesses.</p>
					<h2>13. How to complain</h2>
						<p>Please contact us if you have any queries or concerns about our use of your information (see "<strong>How to contact us</strong>" below). We hope we will be able to resolve any issues you may have.</p>
						<p>You also have the right to lodge a complaint with the Information Commissioner.</p>
						<p>The Information Commissioner can be contacted at <a href="https://ico.org.uk/make-a-complaint" target="_blank">https://ico.org.uk/make-a-complaint</a> or telephone: 0303 123 1113.</p>
					<h2>14. Changes to this privacy policy</h2>
						<p>We may change this privacy policy from time to time. When we make significant changes we will take steps to inform you, for example via the Website or by other means, such as email.</p>
					<h2>15. How to contact us</h2>
						<p>You can contact us by email if you have any questions about these termsprivacy policy or the information we hold about you, to exercise a right under data protection law or to make a complaint. You may send your email to <c.EmailLink/>.</p>
					<c.BackLink/>
				</main>
				<c.Footer signup={false}/>
			</div>
		);
	}
}

export class CookiePolicy extends Component {
	render() {
		return (
			<div className="grid">
				<c.Header/>
				<main>
					<h1>Cookie Policy</h1>
						<p>Please read this cookie policy carefully as it contains important information on who we are and how we use cookies on our Website.
							This policy should be read together with our <a href="privacy-policy">Privacy Policy</a> which set out who we are, how to contact us,
							what data is collected, how and why we collect, store, use and share personal information generally, as well as your rights in relation
							to your personal information and details of how to contact us and supervisory authorities if you have a complaint.</p>
					<h2>1. Cookies</h2>
						<p>A cookie is a small text file which is placed onto your device (e.g. your smartphone or other electronic device) when you use the Website.
							When we use cookies on the Website, you will always be informed by a pop-up within the Website. </p>
						<p>Cookies help us to recognise you and your device and allow us to store some information about your preferences or past actions, and remember your preferences next time you visit the Website.</p>
						<p>For example, we may monitor how many times you use our Website, which parts of the Website you go to, services browsed by you.
							This information helps us to understand the use of the Website by our users. Some of this data will be aggregated or statistical, which means that we will not be able to identify you individually.</p>
						<p>For further information on our use of cookies, including a detailed list of your information which we and others may collect through cookies, please see below.</p>
						<p>For further information on cookies generally, including how to control and manage them, visit the guidance on cookies published
							by the UK Information Commissioner's Office, <a href="www.aboutcookies.org">www.aboutcookies.org</a> or <a href="www.allaboutcookies.org">www.allaboutcookies.org</a>.</p>
					<h2>2. Consent to use cookies and changing settings</h2>
						<p>We will ask for your consent to place cookies or other similar technologies on your device, except where they are essential for us to provide you with a service that you have requested
							(e.g. to allow you to remain logged-in to the Website as you navigate within the Website and use the Website functionalities).</p>
						<p>You can withdraw any consent to the use of cookies or manage any other cookie preferences by using the tools or prompts made available to you within the Website itself.
							It may be necessary to refresh or restart the Website for the updated settings to take effect.</p>
					<h2>3. Our use of cookies</h2>
						<p>We may use any or all of the following cookies:</p>
						<ol>
							<li><strong>Strictly necessary cookies</strong>: These are cookies that are required for the operation of our Website.
								These essential cookies are always enabled because the Website will not work properly without them.
								They include, for example, cookies that enable you to log into secure areas of the Website, use a shopping cart or make use of e-billing services.
								You can switch off these cookies in your browser settings but then you may not be able to access all or parts of our Website.</li>
							<li><strong>Analytical or performance cookies</strong>: These allow us to recognise and count the number of visitors and to see how visitors move around the Website when they are using it.
								This helps us to improve the way the Website works, for example, by ensuring that users are finding what they are looking for easily.</li>
							<li><strong>Functionality cookies</strong>: These are used to recognise when you return to the Website. This enables us to personalise our content for you, greet you by name and remember your preferences.</li>
							<li><strong>Targeting cookies</strong>: These cookies record your visit to the Website, the pages you have visited and the links you have followed.
								We will use this information to make the Website and the advertising displayed on it more relevant to your interests.</li>
						</ol>
						<p>The table below provides more information about the individual cookies we use and why:</p>
						<table>
							<tr>
								<th width="20%">Cookie</th>
								<th width="10%">Name</th>
								<th width="40%">Purpose</th>
								<th width="30%">Whether cookie is essential</th>
							</tr>
							<tr>
								<td>Website session identifier</td>
								<td>wsid</td>
								<td>Identifies a session initiated by you on the website. If this cookie is present, the website is able to access your account without forcing you to sign in every time you return.</td>
								<td>No. We will request your consent before placing this cookie.</td>
							</tr>
						</table>
					<h2>4. How to turn off all cookies and consequences of doing so</h2>
						<p>If you do not want to accept any cookies, you may be able to change your device settings so that cookies (including those which are essential to the services requested) are not accepted.
							If you do this, please be aware that you may lose some of the functionality of the Website. For further information about cookies and how to disable them please go to the guidance on cookies
							published by the UK Information Commissioner's Office, <a href="www.aboutcookies.org">www.aboutcookies.org</a> or <a href="www.allaboutcookies.org">www.allaboutcookies.org</a>.</p>
					<h2>5. Changes to this policy</h2>
						<p>This policy was published on 26 May 2024 and last updated on 26 May 2024. We may change this cookies policy from time to time, when we do we will inform you via the Website
							or by sending an email to the email address you provided when you signed up to the Website.</p>
					<c.BackLink/>
				</main>
				<c.Footer signup={false}/>
			</div>
		);
	}
}

export class NotFound extends Component {
	render() {
		return (
			<div className="grid">
				<c.Header/>
				<main className="fullHeight">
					<p>This page doesn't exist.</p>
					<c.BackLink/>
				</main>
				<c.Footer signup={false}/>
			</div>
		);
	}
}

export class Placeholder extends Component {
	render() {
		return (
			<div className="grid">
				<c.Header/>
				<main className="fullHeight">
					<p>This page is a placeholder.</p>
					<c.BackLink/>
				</main>
				<c.Footer signup={false}/>
			</div>
		);
	}
}
