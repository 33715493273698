import ReactDOM from "react-dom/client";
import HttpsRedirect from 'react-https-redirect';
import { StrictMode } from "react";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import * as pages from "./pages";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<HttpsRedirect>
		<StrictMode>
			<Router>
				<Routes>
					<Route path="/" element={ <pages.Home/> } />
					<Route path="/help" element={ <pages.Help/> } />
					<Route path="/terms-of-service" element={ <pages.Terms/> } />
					<Route path="/website-terms" element={ <pages.WebsiteTerms/> } />
					<Route path="/privacy-policy" element={ <pages.Privacy/> } />
					<Route path="/cookie-policy" element={ <pages.CookiePolicy/> } />
					<Route path="/change-email-address" element={ <pages.ChangeEmailAddress/> } />
					<Route path="*" element={ <pages.NotFound/> } />
				</Routes>
			</Router>
		</StrictMode>
	</HttpsRedirect>
);
